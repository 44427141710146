<template>
    <div class="container-lg mt-3 mt-md-5 mb-3 mb-md-5">

        <div v-if="!survey.published" class="posts-empty">
            <div class="posts-empty-inner">
                <div class="posts-empty-inner_img">
                    <img loading="lazy" src="@/assets/img/text-box.png" width="80" height="80" alt="Empty box"/>
                </div>
                    <h4>Your survey has not been published.</h4>
                    <h6>Looks like your survey is still a draft. <router-link class="font-weight-semibold" :to="`/profile/surveys/view/${this.code}/questionnaire`">Continue editing</router-link></h6>
            </div>
        </div>    

        <div v-else-if="survey.published && survey.visibility == 'limited'" class="posts-empty">
            <div class="posts-empty-inner">
                <div class="posts-empty-inner_img">
                    <img loading="lazy" src="@/assets/img/secret.png" width="80" height="80" alt="Empty box"/>
                </div>
                    <h4>Your survey visibility is set to {{ survey.visibility }}.</h4>
                    <h6>Please update your survey <router-link class="font-weight-semibold" :to="`/profile/surveys/view/${this.code}/settings`">settings</router-link> to enable public sharing.</h6>
            </div>
        </div>                  

        <!-- Share options -->
        <div v-else class="survey-settings">

            <h4 class="font-weight-semibold">{{ survey.title }}</h4>

                <div class="mb-3">
                    <h6 class="small-title">Link</h6>
                    <div class="input-group">
                        <input
                            class="form-control form-control-lg large-input"
                            v-on:focus="$event.target.select()"
                            ref="surveylink"
                            type="url"
                            :value="survey.publicUrl"
                            readonly/>
                        <button
                            class="custom-btn custom-btn-secondary"
                            id="copy-btn"
                            @click="copy">
                            <font-awesome-icon icon="copy" />
                            <span class="d-none d-md-inline">Copy Link</span>
                           
                        </button>
                        </div>
                        <p class="form-text">Copy and paste this link anywhere (e.g., in an email, text, or social media) to share your survey.</p>
                    </div>

                    <div class="mb-3">
                        <h6 class="small-title">Share</h6>
                        <ul class="survey-share-buttons">
                            <li>
                                <ShareNetwork
                                    network="email"
                                    :url="survey.url"
                                    title="Please spare a minute to take our survey."
                                    :description="survey.description"
                                    hashtags="openinstitute">
                                    <a href="#" class="share-email">
                                        <span class="share-icon"><font-awesome-icon icon="envelope" /></span>
                                        <span class="share-text d-none d-sm-inline-block">Email</span>
                                    </a>
                                </ShareNetwork>
                            </li>
                            <li>
                                <ShareNetwork
                                    network="whatsapp"
                                    :url="survey.url"
                                    title="Please spare a minute to take our survey."
                                    :description="survey.description"
                                    hashtags="openinstitute">
                                    <a href="whatsapp://send?text=The text to share!" data-action="share/whatsapp/share" class="share-wh">
                                        <span class="share-icon"><font-awesome-icon :icon="['fab', 'whatsapp']" /></span>
                                        <span class="share-text d-none d-md-inline-block">WhatsApp</span>
                                    </a>
                                </ShareNetwork>
                            </li>
                            <li>
                                <ShareNetwork
                                    network="facebook"
                                    :url="survey.url"
                                    title="Please spare a minute to take our survey."
                                    :description="survey.description"
                                    hashtags="openinstitute">
                                    <a href="https://www.facebook.com/dialog/feed?&app_id=APP_ID&link=URI&display=popup&quote=TEXT&hashtag=#HASHTAG" target="_blank" class="share-fb">
                                        <span class="share-icon"><font-awesome-icon :icon="['fab', 'facebook-f']" /></span>
                                        <span class="share-text d-none d-md-inline-block">Facebook</span>
                                    </a>
                                </ShareNetwork>
                            </li>
                            <li>
                                <ShareNetwork
                                    network="twitter"
                                    :url="survey.url"
                                    title="Please spare a minute to take our survey."
                                    :description="survey.description"
                                    hashtags="openinstitute">
                                    <a href="http://www.twitter.com/intent/tweet?url=URL_HERE&via=TWITTER_HANDLE_HERE&text=TITLE_HERE" target="_blank" class="share-tw">
                                        <span class="share-icon"><font-awesome-icon :icon="['fab', 'twitter']" /></span>
                                        <span class="share-text d-none d-md-inline-block">Twitter</span>
                                    </a>
                                </ShareNetwork>
                            </li>
                        </ul>
                    </div>
                <!-- End survey settings -->
            </div>
    </div>
    <!-- End container-lg -->
</template>

<script>
export default {
    name: "SurveyShare",
    props: ['code', 'url'],
    methods: {
        copy() {
          this.$refs.surveylink.focus();
          document.execCommand('copy');
          this.$toaster.success('Link copied to clipboard!');
        }
    },    
    data() {
          return {
              survey: {data: []},
          }
      },
      mounted() {
          this.$http.get('/api/find/survey/' + this.code).then(res => {
          this.survey = res.data.data
        })
      }
}
</script>

<style scoped>
</style>